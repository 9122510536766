<template>
    <navigation title="Bantuan dan Layanan"/>

    <ul class="list-group my-3 rounded-3">
        <li class="list-group-item d-flex justify-content-between p-3" @click="openFAQ">
            <span><i class="bi bi-question-circle fs-3 text-primary me-2"></i> FAQ</span>
            <i class="bi bi-chevron-double-right flex-shrink-1"></i>
        </li>
        <li class="list-group-item d-flex justify-content-between p-3" @click="openNDA">
            <span><i class="bi bi-exclamation-circle fs-3 text-primary me-2"></i> Syarat dan Ketentuan</span>
            <i class="bi bi-chevron-double-right flex-shrink-1"></i>
        </li>
    </ul>

    <bottom-nav/>
</template>

<script>
    import Navigation from "../components/Navigation";
    import BottomNav from "../components/BottomNav";
    export default {
        name: "Support",
        components: {BottomNav, Navigation},
        methods: {
            openFAQ(){
                this.$router.push('/faq')
            },
            openNDA(){
                this.$router.push('/nda')
            },
        }
    }
</script>

<style scoped>

</style>
