<template>
    <div class="vh-100 main-section">
        <div class="container mt-5 pt-5 px-4">
            <p class="text-info fw-bolder fs-3 p-0 my-0">Selamat Datang di PRiADI!</p>
            <p class="fs-6 p-0 mb-5">Masuk dengan Email dan Kata Sandi Anda</p>

            <div class="form-floating mb-3 d-block mx-auto col-11">
                <input v-model="email" type="email" class="form-control" id="floatingInput"
                       placeholder="name@example.com">
                <label for="floatingInput"><i class="bi bi-envelope-fill me-2"></i> Email</label>
            </div>
            <p class="text-danger mb-3">
                {{errors['email']}}
            </p>
            <div class="form-floating mb-2 d-block mx-auto col-11">
                <input v-model="password" type="password" class="form-control" id="floatingPassword"
                       placeholder="Password">
                <label for="floatingPassword"><i class="bi bi-key-fill me-2"></i> Kata Sandi</label>
            </div>
            <p class="text-danger mb-3">
                {{errors['password']}}
            </p>
            <!--        <div class="d-flex justify-content-end mb-5">-->
            <!--            <router-link to="/forgot_password" class="text-info fw-bold text-decoration-none">Lupa Kata Sandi</router-link>-->
            <!--        </div>-->
            <div class="d-grid gap-2 col-3 mx-auto mb-5">
                <button class="btn btn-info shadow text-white" type="button" @click="authenticate">MASUK</button>
            </div>
            <p class="text-center" v-if="this.$store.getters.getRole==='individual'">Belum punya akun ? <br> <router-link to="/register" class="fw-bold text-decoration-none text-info">DAFTAR</router-link></p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import Swal from 'sweetalert2'

    export default {
        name: "Login",
        data() {
            return {
                errors: [],
                email: '',
                password: '',
            }
        },
        mounted() {
            // document.body.style.backgroundImage = "url('/img/login.png')"
            // document.body.style.backgroundSize = 'cover';

            if (this.$store.getters.isAuth) {
                this.$router.push('/transactions')
            }
        },
        methods: {
            async authenticate() {
                this.errors = [];

                if (this.email === '') {
                    this.errors['email'] = 'Email tidak boleh kosong!';
                }
                if (this.password === '') {
                    this.errors['password'] = 'Password tidak boleh kosong!';
                }
                if (!this.errors.email && !this.errors.password) {
                    let self = this;
                    await axios.post('/api/login', {
                        email: this.email,
                        password: this.password
                    }, {withCredentials: true})
                        .then(function () {
                            self.$store.dispatch('setAuth', true);
                            axios.get('/api/user', {withCredentials: true})
                                .then(function (response) {
                                    self.$store.dispatch('setUser', response.data)
                                }).catch(function (error) {
                                self.$store.dispatch('setUser', {id: null, email: null})
                                self.$store.dispatch('setAuth', false)
                                console.log(error)
                            })
                            if(self.$store.getters.getRole === 'hr')
                                self.$router.push('/transactions')
                            else
                                self.$router.push('/package')
                        }).catch(function (error) {
                            console.log(error.response);
                            let errorMsg = '';
                            errorMsg = error.response.data.detail;
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops',
                                text: errorMsg,
                            })
                        })
                }
            }
        }
    }
</script>

<style scoped>
    .main-section {
        background-image: url("/img/login.png");
        background-size: contain;
        background-repeat: no-repeat;
    }
</style>
