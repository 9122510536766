<template>
    <Navigation title="Pilih Paket Tes"/>

    <div class="pb-5 bg-finger">
        <div class="container" style="height: 150px">
            <div class="pt-3" v-if="this.$store.getters.getUser.id">
                <h5 class="text-white">Halo {{this.$store.getters.getUser.fullname}}, <br> Selamat datang di PRiADI</h5>
                <p class="lead text-white fs-6">Pilih paket tes untuk mengenal dirimu</p>
            </div>
            <div class="pt-3" v-else>
                <h5 class="text-white">Halo, Selamat datang di PRiADI</h5>
                <p class="m-0 text-white">Klik <router-link to="/auth" class="fw-bold text-decoration-none text-warning">disini</router-link> untuk login</p>
                <p class="m-0 text-white">Belum memiliki akun?
                    <router-link to="/register" class="fw-bold text-decoration-none" style="color: yellow">Daftar Sekarang
                    </router-link>
                </p>
            </div>
        </div>
    </div>

    <div class="container-fluid" style="margin-top: -5em">
        <div class="d-flex align-items-center overflow-scroll">
            <img v-for="i in 6" v-bind:key="i" v-bind:src="`/img/slide-home/${i}.png`" class="w-75 me-2" alt="">
        </div>
    </div>

    <div class="container mb-5 pb-3 padding-tab-content">
        <ul class="nav nav-pills" id="myTab" role="tablist">
            <li class="nav-item me-3" role="presentation">
                <button class="badge rounded-pill fs-6 border-0"
                        v-bind:class="selectedPills ? 'bg-secondary' : 'bg-primary'" id="test-tab" data-bs-toggle="tab"
                        data-bs-target="#test" type="button" role="tab" aria-controls="test"
                        aria-selected="false" @click="this.selectedPills=false">Paket Tes
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="badge rounded-pill fs-6 border-0"
                        v-bind:class="selectedPills ? 'bg-primary' : 'bg-gold'" id="promo-tab" data-bs-toggle="tab"
                        data-bs-target="#promo" type="button" role="tab" aria-controls="promo"
                        aria-selected="false" @click="this.selectedPills=true">Promo
                </button>
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="test" role="tabpanel" aria-labelledby="test-tab">
                <div class="col mb-3" v-for="(subcategory, index) in get_subcategory" v-bind:key="subcategory">
                    <div class="card shadow package-card p-0" @click="openDetail(index)">
                        <div class="card-body text">
                            <span class="text-primary d-block fw-bold fs-5">{{subcategory.name}}</span>
                            <span class="d-block text-muted" style="font-size: 0.75em; font-weight: 500">Untuk {{subcategory.grouping}}</span>
                            <div class="d-flex justify-content-between align-items-end mt-4">
                                <span class="fw-bold fs-6 d-block">
                                    {{'Rp.'+Intl.NumberFormat('en-US').format(subcategory.price)+',-'}} <br>
                                    <span class="fw-normal" v-if="subcategory.is_consultation==='1'">+ Free konsultasi</span>
                                </span>
                                <img src="/img/choose_ribbon.png" class="position-absolute end-0 w-25" style="bottom: 10%" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="promo" role="tabpanel" aria-labelledby="promo-tab">
                <div class="card shadow-lg" style="border-radius: 20px" v-if="!get_promo">
                    <div class="card-body">

                        <p style="font-size: 10pt; text-align: justify">Untuk mendapatkan harga promo, silahkan masukan kode voucher yang
                            Anda miliki. <br> Jika Anda belum memiliki kode voucher, silahkan hubungi
                            <a href="https://wa.me/+628122082122?text=Saya%20ingin%20mendapatkan%20kode%20voucher%20tes%20PRiADI">call center PRiADI</a></p>
                        <input type="text" v-model="referral_code" name="" class="form-control w-75 mx-auto" id="">
                        <button class="btn text-white d-block mx-auto mt-2 fs-6"
                                style="background-color: #30BACA" @click="select_promo(referral_code)">SUBMIT
                        </button>
                    </div>
                </div>
                <div class="row row-cols-1 g-2" v-else>
                    <div class="col" v-for="(promo, index) in get_promo" v-bind:key="promo">
                        <div class="card shadow package-card pb-3 padding-card-promo" @click="openDetail(index, 'yes')">
                            <img src="/img/promo_ribbon.png" class="position-absolute left-0 w-25" style="top: 10%"
                                 alt="">
                            <div class="card-body text">
                                <span class="text-primary d-block fw-bold fs-5">{{promo.name}}</span>
                                <span class="d-block text-muted" style="font-size: 0.75em; font-weight: 500">Untuk Professional | Pelajar | Individu</span>
                                <div class="d-flex justify-content-between align-items-end mt-3">
                                    <div>
                                        <span class="fw-bold me-1 d-block">{{'Rp.'+Intl.NumberFormat('en-US').format(promo.price)}}
                                            <s class="fw-bold text-danger ">{{'Rp.'+Intl.NumberFormat('en-US').format(promo.price_real)}}</s>
                                        </span>
                                        <span class="fw-normal" v-if="promo.is_consultation==='1'">+ Free konsultasi</span>
                                    </div>
                                    <img src="/img/choose_ribbon.png" class="position-absolute end-0 w-25" style="bottom: 10%" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <BottomNav/>
</template>

<script>
    import Navigation from "../components/Navigation";
    import BottomNav from "../components/BottomNav";
    import axios from 'axios';

    export default {
        name: "Home",
        components: {
            BottomNav,
            Navigation,
        },
        data() {
            return {
                selectedPills: false,
                referral_code: '',
                get_subcategory: '',
                get_promo: '',
            }
        },
        created() {
            this.select_subcategory();
        },
        methods: {
            select_subcategory() {
                let self = this;
                axios.get('https://api.priadi.id/api/list_report', {
                    headers: {'x-api-key': 12345}
                })
                    .then((response) => {
                        self.get_subcategory = response.data.list_report
                    }).catch((error) => {
                    console.log(error)
                })
            },
            select_promo(referral_code) {
                let self = this;
                axios.get('https://api.priadi.id/api/list_report', {
                    params: {reff_code: referral_code},
                    headers: {'x-api-key': 12345}
                })
                    .then((response) => {
                        self.get_promo = response.data.list_report
                    }).catch((error) => {
                    console.log(error)
                })
            },
            openDetail(index, isPromo = 'no') {
                if (isPromo === 'no') {
                    this.$store.dispatch('setPackage',this.get_subcategory[index])
                    this.$router.push({name: 'Package Detail'})
                } else {
                    let package_obj = this.get_promo[index]
                    package_obj.voucher_code = this.referral_code
                    this.$store.dispatch('setPackage', package_obj)
                    this.$router.push({name: 'Package Detail'})
                }
            }
        },
        mounted() {
            document.body.style.backgroundColor = '#EEEFFF'
            this.package = this.$store.getters.getPackage
        },
        unmounted() {
            document.body.style.backgroundColor = '#FFFFFF'
        },
    }
</script>

<style scoped>
    .bg-gold{
        background-color: #f39c12;
    }

    .bg-finger {
        background: #08A0F1 url("/img/fpimageup.png");
        background-size: cover;
    }

    .package-card {
        border-radius: 20px;
    }

    .padding-tab-content {
        padding-top: 1em;
    }

    @media only screen and (min-width: 320px) {
        .padding-card-promo {
            padding-top: 1.5em;
        }

        .package-name {
            font-size: 8pt;
        }

        .package-target {
            font-size: 6pt;
        }

        .package-consultation {
            font-size: 6pt;
        }

        .package-price {
            font-size: 7pt;
        }

        .package-btn {
            font-size: 9pt;
        }
    }

    @media only screen and (min-width: 375px) {
        .padding-card-promo {
            padding-top: 1.5em;
        }

        .package-name {
            font-size: 10pt;
        }

        .package-target {
            font-size: 7pt;
        }

        .package-consultation {
            font-size: 7pt;
        }

        .package-price {
            font-size: 10pt;
        }

        .package-btn {
            font-size: 10pt;
        }
    }

    @media only screen and (min-width: 425px) {
        .padding-card-promo {
            padding-top: 2em;
        }

        .package-name {
            font-size: 11pt;
        }

        .package-target {
            font-size: 7pt;
        }

        .package-consultation {
            font-size: 7pt;
        }

        .package-price {
            font-size: 10pt;
        }

        .package-btn {
            font-size: 10pt;
        }
    }

    @media only screen and (min-width: 768px) {
        .padding-card-promo {
            padding-top: 3em;
        }

        .package-name {
            font-size: 18pt;
        }

        .package-target {
            font-size: 12pt;
        }

        .package-consultation {
            font-size: 12pt;
        }

        .package-price {
            font-size: 12pt;
        }

        .package-btn {
            font-size: 14pt;
        }
    }
</style>
