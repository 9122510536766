import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    state: {
        selectedMenu: '',
        user:{},
        isAuthenticated: false,
        role: '',
        selectedPackage: {},
        isLoading: false,
        checkoutOrder: {},
        currentTransaction: ''
    },
    mutations: {
        SET_MENU: (state, menu) => state.selectedMenu = menu,
        SET_AUTH: (state, auth) => state.isAuthenticated = auth,
        SET_USER: (state, user) => state.user = user,
        SET_ROLE: (state, role) => state.role = role,
        SET_PACKAGE: (state, selectedPackage) => state.selectedPackage = selectedPackage,
        SET_LOADING: (state, isLoading) => state.isLoading = isLoading,
        SET_CHECKOUT: (state, checkoutOrder) => state.checkoutOrder = checkoutOrder,
        SET_TRANSACTION: (state, currentTransaction) => state.currentTransaction = currentTransaction,
    },
    actions: {
        setMenu: ({commit}, menu) => commit('SET_MENU', menu),
        setAuth: ({commit}, auth) => commit('SET_AUTH', auth),
        setUser: ({commit}, user) => commit('SET_USER', user),
        setRole: ({commit}, role) => commit('SET_ROLE', role),
        setPackage: ({commit}, selectedPackage) => commit('SET_PACKAGE', selectedPackage),
        setLoading: ({commit}, isLoading) => commit('SET_LOADING', isLoading),
        setCheckout: ({commit}, checkoutOrder) => commit('SET_CHECKOUT', checkoutOrder),
        setTransaction: ({commit}, currentTransaction) => commit('SET_TRANSACTION', currentTransaction),
    },
    modules: {},
    getters: {
        whatMenu: state => state.selectedMenu,
        isAuth: state => state.isAuthenticated,
        getUser: state => state.user,
        getRole: state => state.role,
        getPackage: state => state.selectedPackage,
        isLoading: state => state.isLoading,
        getCheckout: state => state.checkoutOrder,
        getTransaction: state => state.currentTransaction,
    },
    plugins: [createPersistedState()]
})
