<template>
    <Navigation title="Akun Saya"/>
    <div class="pb-5 bg-finger">
        <div class="container" style="height: 150px">

        </div>
    </div>

    <div class="container-fluid" style="margin-top: -5em;">
        <div class="card">
            <div class="card-body py-4">
                <h2 class="text-center text-muted fw-bold">{{this.$store.getters.getUser.fullname}}</h2>
                <p class="text-center text-muted m-0">{{this.$store.getters.getUser.email}}</p>
            </div>
        </div>
    </div>

    <div class="container mb-5 pb-3 mt-3">
        <ul class="list-group mb-3 rounded-3">
            <li class="list-group-item d-flex justify-content-start">
                <i class="bi bi-calendar-week text-primary me-2"></i>
                {{convertDateID(this.$store.getters.getUser.birthdate)}}
            </li>
            <li class="list-group-item d-flex justify-content-start">
                <i class="bi bi-phone text-primary me-2"></i>
                {{this.$store.getters.getUser.phone_number}}
            </li>
            <li class="list-group-item d-flex justify-content-between click-effect ripple" @click="changePassword()">
                <span><i class="bi bi-shield-shaded text-primary me-2"></i> Ubah kata sandi</span>
                <i class="bi bi-chevron-double-right flex-shrink-1"></i>
            </li>
        </ul>

        <ul class="list-group mb-5 rounded-3">
            <li class="list-group-item d-flex justify-content-between click-effect ripple" @click="openSupport">
                <span class="d-flex justify-content-start align-items-center" >
                    <i class="bi bi-question-circle text-primary me-2"></i>
                    <span>Bantuan dan Layanan <br> <small class="text-muted">FAQ, Syarat dan Ketentuan</small></span>
                </span>
                <i class="bi bi-chevron-double-right flex-shrink-1 align-self-center"></i>
            </li>
            <li class="list-group-item d-flex justify-content-between click-effect ripple" @click="openContact">
                <span class="d-flex justify-content-start align-items-center">
                    <i class="bi bi-telephone-fill text-primary me-2"></i>
                    <span>Kontak Kami <br> <small class="text-muted">Email, Telepon, Whatsapp</small></span>
                </span>
                <i class="bi bi-chevron-double-right flex-shrink-1 align-self-center"></i>
            </li>
        </ul>

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" style="height: 35px; border-radius: 35px; background-color: #2283C1"
                    @click="logout">Keluar
                <i class="bi bi-box-arrow-right"></i></button>
        </div>
    </div>

    <BottomNav/>
</template>

<script>
    import axios from 'axios'
    import BottomNav from "../components/BottomNav";
    import Navigation from "../components/Navigation";

    export default {
        name: "Profile",
        components: {Navigation, BottomNav},
        mounted() {
            document.body.style.backgroundColor = '#EEEEEE'
            // console.log(this.$store.getters.getUser)
        },
        unmounted() {
            document.body.style.backgroundColor = '#FFFFFF'
        },
        methods: {
            openContact() {
                this.$router.push("/contact")
            },
            openSupport() {
                this.$router.push("/support")
            },
            changePassword() {
                this.$router.push("/change_password")
            },
            logout() {
                let self = this;
                axios.post('/api/logout', {}, {withCredentials: true})
                    .then(function (response) {
                        if (response.data.message) {
                            self.$store.dispatch('setAuth', false)
                            self.$store.dispatch('setUser', {'id': null, 'email': null})
                            self.$router.push('/')
                        }
                    }).catch(function (error) {
                    console.log(error.response);
                })
            },
            convertDateID(date) {
                /*const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
                    "Juli", "Agustus", "September", "Oktober", "Nopember", "Desember"
                ];*/
                let months = {};
                months['01'] = "Januari";
                months['02'] = "Februari";
                months['03'] = "Maret";
                months['04'] = "April";
                months['05'] = "Mei";
                months['06'] = "Juni";
                months['07'] = "Juli";
                months['08'] = "Agustus";
                months['09'] = "September";
                months['10'] = "Oktober";
                months['11'] = "Nopember";
                months['12'] = "Desember";
                let new_date;
                if(date){
                    let arr_date = date.split('-');
                    new_date = arr_date[2] + ' - ' + months[arr_date[1]] + ' - ' + arr_date[0]

                } else {
                    new_date = ''
                }

                return new_date
            }
        }
    }
</script>

<style scoped>
    .bg-finger {
        background: #08A0F1 url("/img/fpimageup.png");
        background-size: cover;
    }
</style>
