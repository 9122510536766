<template>
    <div class="bg-light d-flex justify-content-around align-item-center fixed-bottom shadow-lg border border-top">
        <router-link v-if="currentRole == 'individual'" to="/package" class="text-center btn-click-effect text-decoration-none" :class="{'text-primary':selectedMenu==='Package', 'text-black':selectedMenu!=='Package'}" style="font-size: 8pt;"><i class="bi bi-box" style="font-size: 1.8em!important"></i> <br>Home</router-link>
        <router-link to="/transactions" class="text-center btn-click-effect text-decoration-none" :class="{'text-primary':selectedMenu==='Transactions', 'text-black':selectedMenu!=='Transactions'}" style="font-size: 8pt"><i class="bi bi-wallet" style="font-size: 1.8em!important; "></i> <br>Transaksi</router-link>
        <router-link to="/profile" class="text-center btn-click-effect text-decoration-none" :class="{'text-primary':selectedMenu==='Profile', 'text-black':selectedMenu!=='Profile'}" style="font-size: 8pt"><i class="bi bi-person" style="font-size: 1.8em!important; "></i> <br>Profil saya</router-link>
        <a href="https://wa.me/+628122082122" class="text-black text-decoration-none text-center btn-click-effect" style="font-size: 8pt"><i class="bi bi-whatsapp" style="font-size: 1.8em!important; "></i> <br>Call Center</a>
    </div>
</template>

<script>
    export default {
        name: "BottomNav",
        data(){
            return {
                selectedMenu: '',
                currentRole: '',
            }
        },
        created() {
            this.selectedMenu = this.$store.getters.whatMenu
            this.currentRole = this.$store.getters.getRole
        }
    }
</script>

<style scoped>

</style>
