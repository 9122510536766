<template>
    <Navigation title="Kontak Kami"/>

    <ul class="list-group my-3 rounded-3">
        <li class="list-group-item d-flex justify-content-between click-effect" @click="send_mail">
            <span class="d-flex justify-content-start align-items-center">
                <i class="bi bi-envelope-fill text-primary fs-3 me-3"></i>
                <span>Email <br> <small class="text-muted">info@priadi.id</small></span>
            </span>
        </li>
        <li class="list-group-item d-flex justify-content-between click-effect" @click="phone_call">
            <span class="d-flex justify-content-start align-items-center">
                <i class="bi bi-telephone-fill text-primary fs-3 me-3"></i>
                <span>Telepon <br> <small class="text-muted">+62 812 208 2122</small></span>
            </span>
        </li>
    </ul>
    <bottom-nav/>
</template>

<script>
    import Navigation from "../components/Navigation";
    import BottomNav from "../components/BottomNav";

    export default {
        name: "Contact",
        components: {BottomNav, Navigation},
        methods:{
            send_mail(){
                window.open("mailto:info@priadi.id");
            },
            phone_call(){
                window.open("tel:+628122082122")
            }
        }
    }
</script>

<style scoped>

</style>
