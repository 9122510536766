<template>
    <Navigation :title="capture_title"/>
    <div class="pb-5" v-show="!isTutorialWatched">
        <div class="text-center mt-3">
            <p class="lead m-0">Sebelum Melanjutkan,</p>
            <p>Simak terlebih dahulu tutorial singkat dibawah ini:</p>
        </div>

        <iframe id="tutorialPlayer" class="w-100 shadow" style="height: 50vh!important;" src="https://www.youtube.com/embed/bWaZWh6Zlkk?enablejsapi=1&origin=https://apps.priadi.id" title="Tutorial Singkat Pengambilan Sidik Jari" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p class="text-center my-2" v-if="countdown_tutorial>0">Mohon tunggu {{countdown_tutorial}} detik</p>
        <div class="d-grid col-6 mx-auto mt-2 shadow">
            <button type="button" class="btn btn-primary" :class="{'disabled':!isTutorialFinish}" @click="isTutorialWatched=true" id="flashBtn">
                Ambil Sidik Jari
            </button>
        </div>
    </div>

    <Transition name="fade">
        <div class="" v-show="isTutorialWatched">
            <canvas id="photoTaken" class="mx-auto mb-0 d-none" ref="canvas" :width="360" :height="480"></canvas>

            <!-- container foto jari -->
            <div class="container py-3" style="background-color: #EEEEEE;">
                <div class="row row-cols-2">
                    <div v-for="hand in hands" :key="hand" class="col">
                        <p class="text-center fw-bold mb-5">{{hand.name}}</p>
                        <div v-for="finger in hand.fingers" :key="finger.name" @click="changeFinger(finger.name)">
                            <p class="text-center mb-1">{{ finger.name.replace(/_/g," ").toUpperCase() }}</p>
                            <div class="text-center border border-3 mt-0 mb-5" style="border-radius: 20%"
                                 :class="{'border-done':fingers_done[finger.name], 'border-info':finger.name === selected_finger}">
                                <img :id="`img${finger.name}`" :src="fingers_done[finger.name] ? `http://api-apps.priadi.id/media/finger/${transaction_id}_${finger.code}_${finger.name}_ori.jpg` : `/img/finger/${finger.name}.jpg`" style="border-radius: 20%" class="w-100" alt="">
                            </div>
                            <input type="file" class="d-none" :id="`cameraFileInput${finger.name}`" accept="image/*" capture="environment" @change="onFileChange">
                        </div>
                    </div>
                </div>
            </div>
            <!-- container tombol jari -->

            <!-- Tombol Finish -->
            <div class="d-grid g-2 col-12 mx-auto" v-if="Object.values(this.fingers_done).every(item => item === true)">
                <button type="button" class="btn btn-success text-white rounded-0" @click="finish" data-bs-dismiss="modal">
                    Selesai
                </button>
            </div>
            <!-- Tombol Finish -->
        </div>
    </Transition>

    <!-- <BottomNav/> -->

    <!-- Button trigger modal tips -->
    <button type="button" id="btn_modal" style="display: none" class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#staticBackdrop">
        PRiADI Tips
    </button>
    <!-- Button trigger modal tips -->

    <!-- Modal Tips Pengambilan-->
    <div class="modal fade bg-info" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content"
                 style="background-color: transparent!important; border: 0 solid transparent!important;">
                <div class="modal-body rounded rounded-bottom mb-5" style="background-color: white">
                    <h4 class="text-center fw-bold mb-4">Petunjuk Foto Sidik Jari</h4>
                    <div v-for="(tip, index) in tips" :key="tip">
                        <Transition name="list">
                            <div class="d-flex mb-2" v-if="tip.show">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" :id="`tips${index}`" @click="toggleTutorial(index)" :disabled="tip.checked">
                                    <label class="form-check-label" :for="`tips${index}`" v-html="tip.data">
                                    </label>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
                <div class="modal-footer border-0">
                    <Transition name="fade">
                        <div class="d-grid col-6 mx-auto" v-if="isTipsFinish">
                            <button type="button" class="btn" id="" @click="this.isTimerEnabled=true" style="background-color: yellow" data-bs-dismiss="modal">
                                Lanjutkan
                            </button>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Tips Pengambilan-->

    <!-- Modal Retake Finger -->
    <div class="modal fade" id="retakeModal" tabindex="-1" aria-labelledby="retakeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="retakeModalLabel">Ambil Ulang?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="lead">(Pilih salah satu)</p>
                    <div class="d-flex align-items-center gap-2 mb-3">
                        <button class="btn btn-primary mt-3" @click="retakeProcess()">Ya, Lakukan Taking ulang</button>
                    </div>
                    <div class="d-flex align-items-center gap-2 mb-2">
                        <button class="btn btn-danger mt-3" data-bs-dismiss="modal">Tidak</button>
                    </div>
                    <input type="text" id="fingerSel" />
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Retake Finger -->
</template>

<script>
    import Navigation from "../components/Navigation";
    import Swal from 'sweetalert2'
    import axios from 'axios'

    require('form-data')

    export default {
        name: "Capture",
        components: {
            Navigation,
        },
        mounted() {
            document.getElementById("btn_modal").click()
            this.isTutorialWatched = false;
            this.transaction_id = this.$store.getters.getTransaction
            this.checkFinger()
            this.getTransactionData()
        },
        data() {
            return {
                transaction_id: '',
                transaction_data: {},
                hands: [
                    {
                        name: "Tangan Kiri",
                        fingers: [
                            {code: 10, name:"left_thumb"},
                            {code: 11, name:"left_forefinger"},
                            {code: 12, name:"left_middlefinger"},
                            {code: 13, name:"left_thirdfinger"},
                            {code: 14, name:"left_littlefinger"},
                        ]
                    },
                    {
                        name: "Tangan Kanan",
                        fingers: [
                            {code: 21, name:"right_thumb"},
                            {code: 22, name:"right_forefinger"},
                            {code: 23, name:"right_middlefinger"},
                            {code: 24, name:"right_thirdfinger"},
                            {code: 25, name:"right_littlefinger"},
                        ]
                    },
                ],
                tips: [
                    {data: "<b>Gunakan flash</b> untuk pencahayaan agar sidik jari dapat terlihat.", show: true, checked:false},
                    {data: "<b>Gunakan 2x zoom</b> agar sidik jari dapat terpotret dengan jelas.", show: false, checked:false},
                    {data: "Gunakan <b>hanya satu warna latar</b> dan warna latar tidak boleh sama dengan warna kulit. Kami sarankan untuk menggunakan <b>latar hitam</b>.", show: false, checked:false},
                    {data: "<p class='text-danger p-0 m-0 fw-bold'>Posisikan sidik jari anda hingga terlihat tekstur sidik jari.</p>", show: false, checked:false},
                    {data: "Jarak ideal antara jari dan HP anda adalah 10-13cm.", show: false, checked:false},
                    {data: "<b>Hindari pengambilan gambar sidik jari yang berbayang</b>.", show: false, checked:false},
                    {data: "Pastikan jari anda dalam keadaan lembap. (Tidak terlalu kering dan tidak terlalu basah).", show: false, checked:false},
                ],
                retakeModal: null,
                isLoading: false,
                selected_finger: '',
                fingers_done: {
                    left_thumb: false,
                    left_forefinger: false,
                    left_middlefinger: false,
                    left_thirdfinger: false,
                    left_littlefinger: false,
                    right_thumb: false,
                    right_forefinger: false,
                    right_middlefinger: false,
                    right_thirdfinger: false,
                    right_littlefinger: false
                },
                capture_title: "Foto Sepuluh Sidik Jari",
                isTutorialWatched: false,
                isTutorialFinish: false,
                countdown_tutorial: 10,
                isTimerEnabled: false,
                countdown_tips: 7,
                isTipsFinish: false
            }
        },
        methods: {
            retakeFinger() {
                Swal.fire({
                    title: "Ambil ulang",
                    text: "Apakah Anda ingin mengambil ulang foto jari?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya, ambil ulang",
                    cancelButtonText: "Tidak"
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.close()
                        this.retakeProcess()
                    }
                });
            },
            retakeProcess() {
                this.takingFinger()
            },
            takingFinger() {
                document.getElementById("cameraFileInput"+this.selected_finger).click()
            },
            changeFinger(finger_name) {
                this.selected_finger = finger_name

                if(this.fingers_done[finger_name] == true) {
                    this.retakeFinger(finger_name)
                } else {
                    this.takingFinger(finger_name)
                }
            },
            onFileChange(event) {
                const self = this
                const file = event.target.files[0]
                if(file) {
                    const fileReader = new FileReader()

                    fileReader.onload = function(e) {
                        const img = new Image()
                        img.onload = function() {
                            const canvas = document.getElementById('photoTaken')
                            const ctx = canvas.getContext('2d')

                            const MAX_WIDTH = 800;
                            const MAX_HEIGHT = 600;
                            let width = img.width;
                            let height = img.height;

                            if (width > height) {
                                if (width > MAX_WIDTH) {
                                    height *= MAX_WIDTH / width;
                                    width = MAX_WIDTH;
                                }
                            } else {
                                if (height > MAX_HEIGHT) {
                                    width *= MAX_HEIGHT / height;
                                    height = MAX_HEIGHT;
                                }
                            }

                            canvas.width = width
                            canvas.height = height
                            ctx.drawImage(img, 0, 0, width, height)
                            
                            self.sendPicture()
                        }
                        img.src = e.target.result
                    }
                    fileReader.readAsDataURL(file)  
                }
            },  
            async sendPicture() {
                let self = this
                let finger_image = document.getElementById("photoTaken").toDataURL('image/jpg', 1.0)
                let trans_id = this.transaction_id
                let finger_name = this.selected_finger

                let form = new FormData()
                form.append('id', trans_id)
                form.append('fingername', finger_name)
                form.append('image', finger_image)

                await axios.post('/api/update_finger', form)
                    .then(function (response) {
                        let swal_icon = response.data.status ? 'success':'error'
                        let swal_text = response.data.status ? 'Tersimpan':'Gagal'
                        Swal.fire({
                            icon: swal_icon,
                            title: swal_text,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        if(response.data.status){
                            self.fingers_done[self.selected_finger] = true;
                            self.selected_finger = ''
                        }

                    }).catch(function (error) {
                        console.log(error)
                    })
            },
            finish() {
                let self = this
                let send_url;
                let send_data;
                if(this.$store.getters.getRole==='hr' || self.transaction_data.status_process=='retake'){
                    send_url = "/api/send_person_priadi"
                    send_data = {transaction_id: self.transaction_id}
                } else {
                    send_url = "/api/set_done"
                    send_data = {id: self.transaction_id}
                }
                axios.post(send_url, send_data).then(function () {
                    // Swal.showLoading()
                    if(self.$store.getters.getRole==='hr' || self.transaction_data.status_process=='retake') {
                        Swal.fire({
                            icon: 'success',
                            title: 'Sukses!',
                            text: '10 Gambar Sidik jari anda telah dikirim',
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: 'Kembali ke transaksi',
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                self.$router.push("/transactions")
                            }
                        })
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Sukses!',
                            text: '10 Gambar Sidik jari anda telah dikirim',
                            showDenyButton: true,
                            showCancelButton: true,
                            confirmButtonText: 'Lanjutkan ke pembayaran',
                            denyButtonText: 'Kembali ke transaksi',
                            cancelButtonText: 'Ambil tes lain',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,

                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                let formData = {}

                                axios.post('/api/create_invoice_pg', {
                                    user_id: self.$store.getters.getUser.id,
                                    transaction_id: self.transaction_id,
                                    package_name: self.transaction_data.package_name,
                                    price: self.transaction_data.total_price,
                                }).then((response)=>{
                                    formData.payment_id = response.data.id
                                    formData.payment_url = response.data.url
                                    formData.trx_id = self.transaction_id
                                    formData.price_sum = self.transaction_data.total_price
                                    formData.package_name = self.transaction_data.package_name
                                    self.$store.dispatch('setCheckout', formData)
                                    self.$router.push('/payment/checkout')
                                })
                            } else if(result.isDenied) {
                                self.$router.push("/transactions")
                            } else if(result.isDismissed) {
                                self.$router.push("/package")
                            }
                        })
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            async checkFinger() {
                let self = this
                await axios.post('/api/check_finger', {id: this.transaction_id})
                    .then(function (response){
                        let response_data = response.data;
                        Object.keys(response_data).forEach(function (key) {
                            if(response_data[key] !== null){
                                self.fingers_done[key] = true
                            }
                        })
                    }).catch(function (error) {
                        console.log(error)
                    })
            },
            async getTransactionData() {
                let self = this
                await axios.post('/api/check_transaction', {id: this.transaction_id})
                    .then(function (response){
                        self.transaction_data = response.data;
                    }).catch((error) => console.log(error))
            },
            toggleTutorial(id) {
                this.tips[id].checked = true;
                
                if(this.tips.length - 1 == id){
                    this.isTipsFinish = true
                } else {
                    this.tips[id+1].show = true
                }
            }
        },
        watch: {
            isTimerEnabled(value) {
                if (value) {
                    setTimeout(() => {
                        this.countdown_tutorial--;
                    }, 1000);
                }
            },

            countdown_tutorial: {
                handler(value) {

                    if (value > 0 && this.isTimerEnabled) {
                        setTimeout(() => {
                            this.countdown_tutorial--;
                        }, 1000);
                    }

                    if(value === 0){
                        this.isTutorialFinish = true;
                    }
                },
                immediate: true // This ensures the watcher is triggered upon creation
            },
        }
    }
</script>

<style scoped>
    .border-done {
        border-color: #0EF522 !important;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }
    .form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
        color: black !important;
        opacity: 1;
    }
</style>
