<template>
    <Navigation title="FAQ"/>
    <div class="container-fluid py-4">
        <p class="lead">FAQ (Frequently Asked Questions)</p>
        <div v-for="(faq, idx) in faqs" v-bind:key="faq" class="accordion" id="accordionExample">
            <div class="accordion-item mb-3">
                <h2 class="accordion-header" :id="'heading'+idx">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+idx" aria-expanded="true" :aria-controls="'collapse'+idx">
                        <div v-html="faq.question"></div>
                    </button>
                </h2>
                <div :id="'collapse'+idx" class="accordion-collapse collapse" :aria-labelledby="'heading'+idx" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <!--                        <img :src="+faq.icon" :alt="faq.title" class="d-block mx-auto w-100">-->
                        <div v-html="faq.answer "></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import Navigation from "../components/Navigation";

    export default {
        name: "FAQ",
        data() {
            return {
                faqs: {}
            }
        },
        components: {Navigation},
        mounted() {
            let self = this;
            axios.get('https://api.priadi.id/api/faq?language=2',{headers:{'x-api-key':12345}})
            .then((response)=> {
                self.faqs = response.data.faq;
            }).catch((error)=> {
                console.log(error)
            })
        }
    }
</script>

<style scoped>

</style>
